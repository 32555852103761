import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { Input, Popover, Select, notification } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Space, Tooltip, Button, Table} from 'antd';
import dayjs from 'dayjs';
import { ColumnsType } from "antd/es/table";
import Text from "antd/es/typography/Text";
import { genGPSPopoverContentDisplay, genGpsInRangeDisplay, s3KeyToFilename, sizeDisplay } from "../common";
import { KilnTypeUIRenderer, genRendererMap } from "../kiln_types/factory";
import { KilnType } from "../kiln_types/common";
import { Can } from "../../permissions";

const { RangePicker } = DatePicker;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare type EventValue<Dayjs> = dayjs.Dayjs | null;
declare type RangeValue<Dayjs> = [EventValue<Dayjs>, EventValue<Dayjs>] | null;

function getSingleKilnLog(s3key: string) {
  const requestOptions = {
    method: 'GET',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
  };
  fetch(`/api/kiln/single/${s3key}`, requestOptions)
    .then(response => response.blob())
    .then(blob => saveAs(blob, s3key))
    .catch(err => {
      console.log("error on file save or download attempt " + err);
    });
}

function triggerSizeCompute(kilnlog_id: string, s3key: string) {
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
    body: JSON.stringify({
      kilnlog_id: kilnlog_id
    }),
  };
  fetch(`/api/kiln/size/${s3key}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        notification.error({
          message: 'Error on size compute.',
          description: 'Please contact support to ensure this is investigated.',
          duration: 4,
          placement: "top",
        });
      }
    })
    .catch(err => {
      console.log("error on size compute request " + err);
    });
}

function triggerAnalysis(kilnlog_id: string, callback: () => void) {
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
  };
  fetch(`/api/kiln/analyse/${kilnlog_id}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        notification.error({
          message: 'Error on analysis run.',
          description: 'Please contact support to ensure this is investigated.',
          duration: 4,
          placement: "top",
        });
      }
      callback();
    })
    .catch(err => {
      console.log("error on analysis request " + err);
      callback();
    });
}

function KilnLogTable(props: any) {

  const kilnRendererMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();
  const cols : ColumnsType<any> = [
    {
      title: 'Kiln ID',
      width: 80,
      dataIndex: 'kiln_id',
      key: 'kiln_id',
      fixed: 'left',
      render: (_: any, record: any) => (
          <a href={`/kiln_detail?id=${record.kiln_id}`}>{record.kiln_id}</a>
        ),
    },
    {
      title: 'Type',
      width: 80,
      dataIndex: 'type',
      key: 'type',
      render: (_: any, record: any) => {
        const renderer : KilnTypeUIRenderer = props.rendererMap.get(record.type as KilnType)
        return renderer.genKilnTypeTag();
      }
    },
    {
      title: 'File Details',
      children: [
        {
          title: 'Log Date',
          width: 105,
          dataIndex: 'logts',
          key: 'logts',
          render: (_: any, record: any) => (
            <>{dayjs(record.logts).format('DD-MMM-YYYY')}</>
          ),
        },
        {
          title: 'Upload Date',
          width: 105,
          dataIndex: 'uploadts',
          key: 'uploadts',
          render: (_: any, record: any) => (
            <>{dayjs(record.uploadts).format('DD-MMM-YYYY')}</>
          ),
        },
        {
          title: 'Filename',
          width: 60,
          dataIndex: 's3key',
          key: 's3key',
          render: (_: any, record: any) => (
            <>{s3KeyToFilename(record.s3key)}</>
          ),
        },
        {
          title: 'Size',
          width: 40,
          dataIndex: 'file_size',
          key: 'file_size',
          render: (_: any, record: any) => (
            <>{sizeDisplay(record.file_size ? record.file_size : 0)}</>
          ),
        }
      ],
    },
    {
      title: 'Analysis Results',
      children: [
        {
          title: 'Phase One',
          dataIndex: 'analysis_detail',
          key: 'analysis_detail',
          align: 'center',
          width: 60,
          render: (_: any, record: any) => {
            const vType: KilnType = record.type as number;
            const renderer: KilnTypeUIRenderer|undefined = kilnRendererMap.get(vType);
            if (renderer)
              return renderer?.genAnalysisDetailOrErrorIndicator(record.analysis_detail, record.error);
            else
              return (<></>);
          },
        },
        {
          title: 'GPS Valid',
          dataIndex: 'gps_in_range',
          key: 'gps_in_range',
          align: 'center',
          width: 60,
          render: (_: any, record: any) => {
            return (
              <Popover content={genGPSPopoverContentDisplay(record.latitude, record.longitude)}>{genGpsInRangeDisplay(record.gps_in_range)}</Popover>
            ) 
          },
        },
        {
          title: 'Phase Two',
          dataIndex: 'burn_valid',
          key: 'burn_valid',
          align: 'center',
          width: 60,
          render: (_: any, record: any) => {
            if (record.validation_detail) {
              const vDetail: any = JSON.parse(record.validation_detail);
              const vType: KilnType = record.type as number;
              const renderer: KilnTypeUIRenderer|undefined = kilnRendererMap.get(vType);
              return (
                <Popover content={renderer?.genKilnValidationDetailPanel(vDetail)}>{renderer?.genKilnValidationWidget(vDetail, record.valid)}</Popover>
              )
            }
            else {
              return (<></>);
            }
          },
        },
      ],
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (_:any, record: any) => {
        return (
          <Space>
            <Can I='download' a='kilnlog'>
              <Button type="dashed" onClick={() => getSingleKilnLog(record.s3key)}>Get</Button>
            </Can>
            <Can I='create' a='analysis'>
              <Button type="dashed" onClick={() => triggerSizeCompute(record.id, record.s3key)}>Size</Button>
              <Button type="dashed" onClick={() => triggerAnalysis(record.id, props.refreshCallback)}>Analyse</Button>
            </Can>
          </Space>
        )
      }
    },
  ];

  return (
    <Table 
      dataSource={props.logs} 
      columns={cols} 
      scroll={{ x: 1200 }} 
      size="small"
      bordered />
  )
}


function KilnLogs(props : any) {

  const [logs, setLogs] = useState<any[]>([]);
  const [facilities, setFacilities] = useState<any[]>([]);
  const defaultPreDate: dayjs.Dayjs = dayjs().subtract(2, 'month');

  const kilnTypeRenderMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();
  
  const [searchParams, setSearchParams] = useState<any>({
    facility_id: 0,
    kilnid: "",
    uploadfrom: defaultPreDate,
    uploadto: dayjs(),
    logfrom: defaultPreDate,
    logto: dayjs(),
    trigger: 0,
  });

  useEffect(() => {
    const executeSearch = async () => {
      await fetch('/api/kiln/logs?' + new URLSearchParams({
        uf: searchParams.uploadfrom.toISOString().split('T')[0],
        ut: searchParams.uploadto.add(1, 'day').toISOString().split('T')[0],
        id: searchParams.kilnid,
        fi: searchParams.facility_id,
        lf: searchParams.logfrom.toISOString().split('T')[0],
        lt: searchParams.logto.add(1, 'day').toISOString().split('T')[0],
      }), {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
      }).then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Error retrieving kiln logs.',
          description: 'An unknown error occurred retrieving the kiln logs. Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
      .then(data => {
        if (data !== null) {
          setLogs(data.kilnlogs);
          setFacilities(data.facilities);
        }
      })
      .catch(err => {
        console.log("error " + err);
      });
    }

    executeSearch();
  }, [searchParams])

  function KilnSearchInputs(props: any) {

    const [searchBuffer, setSearchBuffer] = useState<any>({
      facility_id: searchParams.facility_id,
      kilnid: searchParams.kilnid,
      uploadfrom: searchParams.uploadfrom,
      uploadto: searchParams.uploadto,
      logfrom: searchParams.logfrom,
      logto: searchParams.logto,
    });

    const onChangeUploadDateRange = (value: RangeValue<dayjs.Dayjs>) => {
      setSearchBuffer({
        ...searchBuffer,
        'uploadfrom': value?.[0],
        'uploadto': value?.[1]
      });
    };

    const onChangeBurnDateRange = (value: RangeValue<dayjs.Dayjs>) => {
      setSearchBuffer({
        ...searchBuffer,
        'logfrom': value?.[0],
        'logto': value?.[1]
      });
    };
  
    const onChange = (e: any) => {
      setSearchBuffer({
        ...searchBuffer,
        'kilnid': e.target.value,
      });
    };
  
    const updateSearchParams = () => {
      setSearchParams({
        ...searchParams,
        'uploadfrom': searchBuffer.uploadfrom,
        'uploadto': searchBuffer.uploadto,
        'logfrom': searchBuffer.logfrom,
        'logto': searchBuffer.logto,
        'kilnid': searchBuffer.kilnid,
        'facility_id': searchBuffer.facility_id,
      });
    }

    const handleFacilityChange = (value: string) => {
      setSearchBuffer({
        ...searchBuffer,
        'facility_id': value,
      });
    }

    return (
      <>
        <Space>
          <Text>Facility:</Text>
          <Tooltip title="Restrict to one facility.">
            <Select
              style={{ width: 200 }}
              onChange={handleFacilityChange}
              options={facilities}
              value={searchBuffer.facility_id}
            />
          </Tooltip>
          <Text>Kiln:</Text>
          <Tooltip title="Enter a specific kiln id to see only results for that kiln.">
            <Input style={{ width: 150 }} defaultValue={searchBuffer.kilnid} onChange={onChange} onPressEnter={updateSearchParams}/>
          </Tooltip>
          <Text>Upload Date:</Text>
          <Tooltip title="Upload date range to search (inclusive)">
            <RangePicker allowClear={false} allowEmpty={[false, false]} defaultValue={[searchBuffer.uploadfrom, searchBuffer.uploadto]} onChange={onChangeUploadDateRange} />
          </Tooltip>
          <Text>Log Date:</Text>
          <Tooltip title="Log date range to search (inclusive)">
            <RangePicker allowClear={false} allowEmpty={[false, false]} defaultValue={[searchBuffer.logfrom, searchBuffer.logto]} onChange={onChangeBurnDateRange} />
          </Tooltip>
          <Tooltip title="Search">
            <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={updateSearchParams} />
          </Tooltip>
        </Space>
      </>
    )
  }

  // a hack to cause a change to the search data, so a re-search is triggered to populate
  // the displayed table.
  function refresh(): void {
    setSearchParams({
      ...searchParams, 
      trigger: searchParams.trigger + 1});
  }

  return (
    <div className="wrapper">
    <h1>Kiln Logs</h1>
    <Space direction="vertical">
      <KilnSearchInputs/>
      <KilnLogTable logs={logs} rendererMap={kilnTypeRenderMap} refreshCallback={refresh}/>
    </Space>
  </div>
  );
}

export default KilnLogs;